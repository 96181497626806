<template>
    <div
        class="px-4 sm:px-8 py-4 space-y-10 w-full h-full flex flex-col flex-1"
    >
        <Loader v-if="isPageLoading" :isLoading="isPageLoading" />
        <div v-else class="flex flex-col flex-1 space-y-10">
            <div class="flex justify-between items-center">
                <div>
                    <Text
                        size="3xl"
                        weight="semibold"
                        color="black"
                        :content="`${user?.firstName} ${user?.lastName}`"
                    />
                    <div class="flex items-center space-x-20 mt-4">
                        <Text
                            size="md"
                            weight="semibold"
                            color="black"
                            decoration="underline"
                            :content="user?.email"
                        />
                        <Text
                            size="md"
                            weight="semibold"
                            color="black"
                            :content="`Since ${moment(user?.createdAt)?.format(
                                'M/D/YY'
                            )}`"
                        />
                    </div>
                </div>
                <Avatar size="16" :src="user?.avatar" />
            </div>
            <div>
                <ListViewHeader :title="`Cohorts (${cohorts?.length})`" />
                <div class="space-y-3 mt-5">
                    <CardWithBadge
                        v-for="cohort in cohorts"
                        :key="cohort.id"
                        v-bind="cohort"
                        @onClick="
                            () => {
                                $router.push({
                                    name: 'Ecosystem Cohort Detail',
                                    params: {
                                        companyId: company?.id,
                                        id: cohort?.id?.id,
                                    },
                                });
                            }
                        "
                    />
                </div>
            </div>
            <div>
                <ListViewHeader :title="`Venture Teams (${teams?.length})`" />
                <div class="space-y-3 mt-5">
                    <CardWithBadge
                        v-for="team in teams"
                        :key="team.id"
                        v-bind="team"
                        @onClick="
                            () => {
                                this.$router.push({
                                    name: 'Venture Dashboard',
                                    params: {
                                        ventureId: team?.id,
                                    },
                                    query,
                                });
                            }
                        "
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Text from "@/components/atoms/Text/Text";
import Avatar from "@/components/atoms/Avatar/Avatar";
import ListViewHeader from "../components/molecules/ListViewHeader/WithTitleAndButton/WithTitleAndButton";
import CardWithBadge from "../components/molecules/CardListItem/CardWithBadge/CardWithBadge";
import CardWithImage from "../components/molecules/CardListItem/CardWithImage/CardWithImage";
import moment from "moment";
import { json } from "overmind";
import Loader from "../components/atoms/Loader/Loader.vue";
import { get, upperFirst, groupBy, mapValues, size, max } from "lodash";

export default {
    components: {
        Avatar,
        Text,
        ListViewHeader,
        CardWithImage,
        CardWithBadge,
        Loader,
    },
    data() {
        return {
            cohorts: [],
            teams: [],
            user: null,
            moment,
            company: null,
            isPageLoading: false,
        };
    },
    async mounted() {
        this.isPageLoading = true;
        try {
            if (
                this.state.currentCompany?.id === this.$route.params?.companyId
            ) {
                this.company = this.state.currentCompany;
            } else {
                const companies = await this.actions.company.getCompanies({
                    where: { id: this.$route.params?.companyId },
                    getValues: true,
                    query: "companyDefault",
                });
                this.company = companies[0];
                this.actions.setCurrentCompany(this.company);
            }
            console.log(this.company, "company");
            const items = await this.actions.user.getUsers({
                where: { id: this.$route.params?.id },
                getValues: true,
            });
            this.user = items[0];
            console.log(this.company, "user");
            this.userSurveys = await this.actions.userSurvey.getUserSurveys({
                where: {
                    isArchived_not: true,
                },
                getValues: true,
                all: true,
                fragments: `{id  opportunity {id isArchived} profile {id} survey {id targetCustomerUser {id isArchived}}}`,
            });
            this.company?.programs?.map((p) =>
                p?.cohorts?.map((c) =>
                    c?.ventures
                        ?.filter((v) =>
                            v?.users?.find((u) => u?.id === this.user?.id)
                        )
                        ?.map((v) => {
                            let resp = 0;
                            let contacts = 0;
                            v.targetCustomerUsers?.map((t) => {
                                resp +=
                                    max(
                                        Object.values(
                                            mapValues(
                                                groupBy(
                                                    this.userSurveys?.filter(
                                                        (c) =>
                                                            t.id ===
                                                            c?.survey
                                                                ?.targetCustomerUser
                                                                ?.id
                                                    ),
                                                    "opportunity.id"
                                                ),
                                                size
                                            ),
                                            "this.userSurveys FLEX"
                                        )
                                    ) || 0;
                                contacts +=
                                    t?.contacts?.filter((o) => !o?.isArchived)
                                        ?.length || 0;
                            });
                            const users = [];
                            v?.users?.map((u) =>
                                users?.push(`${u?.firstName} ${u?.lastName}`)
                            );
                            this.teams?.push({
                                ...v,
                                title: v?.name,
                                actionLabel:
                                    json(v?.milestones)?.sort((a, b) =>
                                        a?.sortOrder > b?.sortOrder ? -1 : 1
                                    )?.[0]?.tagLabel || "Getting Started",
                                id: v?.id,
                                description: users?.join(", "),
                                subtitles: [
                                    {
                                        icon: "MapIcon",
                                        name: "Target Customers",
                                        count: v?.users?.length || 0,
                                    },
                                    {
                                        icon: "UsersIcon",
                                        name: "Contacts",
                                        count: contacts,
                                    },
                                    {
                                        icon: "ExclamationIcon",
                                        name: "Opportunities",
                                        count:
                                            v?.opportunities?.filter(
                                                (o) => !o?.isArchived
                                            )?.length || 0,
                                    },
                                    {
                                        name: "Interviews",
                                        count: v?.interviews?.filter(
                                            (o) => !o?.isArchived
                                        )?.length,
                                        icon: "ChatAlt2Icon",
                                    },
                                    {
                                        name: "Responses",
                                        count: resp,
                                        icon: "ChartBarIcon",
                                    },
                                ],
                            });
                        })
                )
            );
            this.company?.programs?.map((p) =>
                p?.cohorts
                    ?.filter((c) =>
                        c?.ventures?.find((v) =>
                            v?.users?.find((u) => u?.id === this.user?.id)
                        )
                    )
                    ?.map((c) => {
                        let opportunities = 0,
                            people = 0;
                        c?.ventures?.map((v) => {
                            people += v?.users?.length || 0;
                            opportunities +=
                                v?.opportunities?.filter(
                                    (o) =>
                                        !o?.isArchived &&
                                        o?.surveyOpportunities?.find(
                                            (s) => s?.responses?.length > 0
                                        )
                                )?.length || 0;
                        });
                        this.cohorts?.push({
                            title: c?.name,
                            id: c,
                            actionLabel: `${moment(c?.startDate).format(
                                "MM/DD"
                            )} - ${moment(c?.endDate).format("MM/DD")}`,
                            subtitles: [
                                {
                                    icon: "LightBulbIcon",
                                    name: "Ventures",
                                    count: c?.ventures?.length || 0,
                                },
                                {
                                    icon: "UsersIcon",
                                    name: "People",
                                    count: people,
                                },
                                {
                                    icon: "ExclamationIcon",
                                    name: "Opportunities",
                                    count: opportunities,
                                },
                            ],
                        });
                    })
            );
        } catch (e) {
            console.log(e);
        } finally {
            this.isPageLoading = false;
        }
    },
};
</script>

<style scoped></style>
